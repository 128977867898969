<template>
    <div class="accounts-setting">
        <div class="page-header">
            <h1>{{ $t('Settings') }} > {{ $t('System Settings') }}</h1>
        </div>
        <div class="page-content">
            <div class="content-header">
                <h2>{{ $t('System Settings') }}</h2>
            </div>
            <div class="main-account">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>{{ $t('System Color Setting') }}</span>
                    </div>
                    <div class="form-content">
                        <el-form ref="form" :model="form">
                            <el-color-picker v-model="form.systemColor"></el-color-picker>
                        </el-form>
                        <div class="submit-wrapper">
                            <el-button class="submit-button" type="primary" @click="submit">
                            {{ $t('Submit') }}
                            </el-button>
                        </div>
                    </div>
                </el-card>
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>{{ $t('Auto Lock / Logout') }}</span>
                    </div>
                    <div class="form-content">
                        <el-form ref="form" :model="form">
                            <el-form-item :label="$t('Auto Logout Time')" style="max-width: 80%">
                                <el-input type="number" v-model="form.logoutTime">
                                    <template slot="append">{{ $t('Mins') }}</template>
                                </el-input>
                            </el-form-item>
                            <el-form-item :label="$t('Auto Lock Time')" style="max-width: 80%">
                                <el-input type="number" v-model="form.lockTime">
                                    <template slot="append">{{ $t('Mins') }}</template>
                                </el-input>
                            </el-form-item>
                        </el-form>
                        <div class="submit-wrapper">
                            <el-button class="submit-button" type="primary" @click="submit">{{
                                $t('Submit')}}</el-button>
                        </div>
                    </div>
                </el-card>
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>{{ $t('Staff Login Able Time') }}</span>
                    </div>
                    <div class="form-content">
                        <el-form ref="form" :model="form">
                            <el-form-item :label="$t('Start Time')">
                                <el-time-select v-model="form.startTime" :picker-options="{
                                    start: '00:00',
                                    step: '00:30',
                                    end: '23:30'
                                }" value-format="HH:mm" placeholder="選擇時間">
                                </el-time-select>
                            </el-form-item>
                            <el-form-item :label="$t('End Time')">
                                <el-time-select v-model="form.endTime" :picker-options="{
                                    start: '00:00',
                                    step: '00:30',
                                    end: '23:30'
                                }" value-format="HH:mm" placeholder="選擇時間">
                                </el-time-select>
                            </el-form-item>
                        </el-form>
                        <div class="submit-wrapper">
                            <el-button class="submit-button" type="primary" @click="submit">{{
                                $t('Submit')}}
                            </el-button>
                        </div>
                    </div>
                </el-card>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'SystemSettings',
    data() {
        const form = {
            logoutTime: 60,
            lockTime: 15,
            startTime: '10:00',
            endTime: '23:30',
            systemColor: '#592C98',
        }
        return {
            form
        }
    },
    methods:{
        async submit(){

        },
    },
    computed: mapState({
        apiUrl: state => state.apiUrl,
    }),
}
</script>

<style scoped lang="scss">
.content-header {
    display: flex;

    h2 {
        flex: 1;
    }

    .action-button {
        margin-right: 20px;
    }
}

.main-account {
    width: 98%;
    margin-left: 20px;
}

.account {
    display: flex;

    .account-email {
        flex: 1;
    }
}

.account-acions {
    .action-link {
        margin-right: 10px;
    }
}

.box-card-header {
    display: flex;

    .card-title {
        flex: 1;
    }
}

.box-card {
    margin-bottom: 20px;
}
</style>