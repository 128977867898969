import { render, staticRenderFns } from "./SystemSettings.vue?vue&type=template&id=028628fa&scoped=true&"
import script from "./SystemSettings.vue?vue&type=script&lang=js&"
export * from "./SystemSettings.vue?vue&type=script&lang=js&"
import style0 from "./SystemSettings.vue?vue&type=style&index=0&id=028628fa&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "028628fa",
  null
  
)

export default component.exports